.ArtCard {
  margin: 5% 0%;

  hr {
    border: 1px solid;
  }

  h1 {
    text-align: center;
  }
}
