@use "../../../scss/colours.scss" as *;

.Preview {
  position: relative;
  margin: 3vmin;
  overflow: hidden;
  height: calc(70vh - 6vmin);
  transition-duration: 0.3s;
  border-radius: 20px;

  h1 {
    margin: 5%;
  }

  .contents {
    margin: 10%;
    width: 80%;
    z-index: 1;
    position: absolute;
    bottom: 0;
    text-align: center;
  }

  .highlight {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    transition-duration: 0.3s;
    opacity: 0;
  }

  &:hover .highlight {
    opacity: 35%;
  }

  img {
    position: absolute;
    top: 0;
    z-index: -1;
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: top;
  }

  .overlay {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    background: $main-background-gradient-down-soft;
    z-index: -1;
  }
}
