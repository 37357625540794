@use "../../scss/colours.scss" as *;

.InquiriesView {
  width: 100%;

  .contact iframe {
    display: block;
    background: $main-background-gradient-down;
  }

  .thankyou {
    margin: 10vmin;
    margin-bottom: 5vmin;
    text-align: center;

    h1 {
      font-size: calc(2em + 2vw);
    }

    p {
      font-size: 1em;
    }
  }
}
