@use "sass:color";

$lightest: #f0f1f0;
$light: #d8e1e0;
$medium: #becf97;
$medium-dark: #899d5b;
$dark: #5b604f;
$darkest: #384533;

// Component Colours
$main-background: $lightest;
$main-background-focus: color.adjust($main-background, $alpha: -0.05);
$main-background-soft: color.adjust($main-background, $alpha: -0.25);
$main-background-softer: color.adjust($main-background, $alpha: -0.5);
$main-background-gradient-down: linear-gradient(
  0deg,
  $main-background 60%,
  rgba(0, 0, 0, 0) 100%
);
$main-background-gradient-down-soft: linear-gradient(
  0deg,
  $main-background-soft 0%,
  rgba(0, 0, 0, 0) 80%
);

$content-outline: $medium;
$content-outline-soft: color.adjust($content-outline, $alpha: -0.25);
$content-outline-softer: color.adjust($content-outline, $alpha: -0.5);
$content-outline-gradient: radial-gradient(
  circle,
  $content-outline-soft 20%,
  rgba(0, 0, 0, 0) 100%
);
$content-outline-gradient-vertical: linear-gradient(
  180deg,
  $content-outline-softer 0%,
  rgba(0, 0, 0, 0) 30%,
  rgba(0, 0, 0, 0) 70%,
  $content-outline-softer 100%
);
$content-outline-gradient-horizontal: linear-gradient(
  90deg,
  rgba(0, 0, 0, 0) 0%,
  $content-outline-softer 30%,
  $content-outline-softer 70%,
  rgba(0, 0, 0, 0) 100%
);
$content-outline-gradient-up: linear-gradient(
  180deg,
  $content-outline-soft 20%,
  rgba(0, 0, 0, 0) 100%
);
$content-outline-gradient-down: linear-gradient(
  0deg,
  $content-outline-soft 20%,
  rgba(0, 0, 0, 0) 100%
);
$content-outline-gradient-left: linear-gradient(
  90deg,
  $content-outline-soft 20%,
  rgba(0, 0, 0, 0) 100%
);

$content-background: $light;
$content-background-soft: color.adjust($content-background, $alpha: -0.5);
$content-background-soft-gradient-up: linear-gradient(
  180deg,
  $content-background-soft 20%,
  rgba(0, 0, 0, 0) 100%
);
$content-background-soft-gradient-down: linear-gradient(
  0deg,
  $content-background-soft 20%,
  rgba(0, 0, 0, 0) 100%
);

// Text Colours
$primary-font: $darkest;

$content-font: $dark;
$content-font-soft: color.adjust($content-font, $alpha: -0.5);
