@use "../../scss/colours.scss" as *;
@use "sass:color";

.PageHeader {
  position: relative;

  .header-container {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;

    .backdrop {
      height: 150%;
      width: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: -2;

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: top;
      }

      .overlay {
        background-image: linear-gradient(
          to bottom,
          color.adjust($main-background, $alpha: -0.7) 30%,
          $main-background
        );
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
      }
    }
  }
}
