@use "../../scss/colours.scss" as *;

.Button {
  padding: 1vmin;
  border-radius: 1vmin;
  align-content: center;
  transition-duration: 0.3s;

  &:hover {
    color: $content-outline;
    background-color: $content-background;
  }
}
