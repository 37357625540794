@use "./colours.scss" as *;

@import url("https://fonts.googleapis.com/css?family=Ephesis");
@import url("https://fonts.googleapis.com/css?family=Montserrat+Alternates");
@import url("https://fonts.googleapis.com/css2?family=Literata:ital,wght@0,200;1,300&display=swap");

@import "./bootstrap-custom-theme.scss";

h1 {
  font-weight: lighter;
  font-family: "Literata", serif;
}

body {
  font-family: "Montserrat Alternates", sans-serif;
  line-height: 2;
}

a {
  color: inherit;
  text-decoration: inherit;
}

iframe {
  border: none;
}

@keyframes hoverIn {
  from {
    opacity: 0;
  }
  to {
    background: $content-background-soft-gradient-down;
  }
}

@keyframes hoverInBorder {
  from {
  }
  to {
    border: 1px solid $content-background-soft;
  }
}

img {
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

.responsive-half-width {
  width: 50%;
}

.responsive-one-third-width {
  width: 33%;
}

.responsive-two-thirds-width {
  width: 66%;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  [class*="responsive-"] {
    width: 100%;
  }
}

button {
  margin: 5px;
  padding: 15px;
  border-radius: 5px;
  align-content: center;
  background-color: $content-outline;
  color: inherit;
  font-family: inherit;
  border: none;
  transition-duration: 0.3s;

  &:hover {
    color: $content-outline;
    background-color: $content-background;
  }
}

.caption {
  font-style: italic;
  text-align: center;
}

.reveal {
  position: relative;
  transform: translateY(50px) scale(0.95);
  opacity: 0.5;
  transition: 0.3s ease-out;

  &.active {
    transform: translateY(0) scale(1);
    opacity: 1;
  }
}
