@use "../../../scss/colours.scss" as *;

.ZiggyCard {
  position: relative;
  margin: 5% 0%;

  .overlay {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    opacity: 0%;
    transition-duration: 0.3s;
  }

  .container:hover .overlay {
    opacity: 35%;
  }
}
