@use "../../scss/colours.scss" as *;

.SiteFooter {
  font-size: x-small;
  color: $medium;
  display: flex;
  align-items: center;
  margin: 1vmin auto;
  width: max-content;
  height: max-content;

  .item {
    flex: 1;
    display: inline;
    margin: auto 2vmin;
    text-align: center;
    align-items: center;
    display: flex;
  }

  img {
    margin: 1vmin;
    display: inline;
  }
}
